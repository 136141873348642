import React from "react"
import Slider from "react-slick"

import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "./slick-theme.css"
import cx from "classnames"
import styles from "./styles.module.scss"

const MobileCarousel = ({
  items,
  transparent,
}) => {

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {items.map(item => (
        <div>
          <div
            className={
              cx(styles.carouselItem, item.colour && styles[item.colour], transparent && styles.transparent)
            }
          >
            <img  className={styles.carouselItemImg} src={item.img} alt="carousel item" />
            <div className={styles.carouselItemContent}>
              <h4>{item.title}</h4>
              {item.header && (<p><b>{ item.header }</b></p>)}
              <p>{item.copy}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default MobileCarousel
