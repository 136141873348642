import React from "react"
import { Link } from "gatsby";
import Layout from "../../../components/layout"
import Seo from "../../../components/Seo"
import {
  DualImageBlock,
  RightDualComponent
} from "../../../components/Grid"
import {
  PrimaryHero,
  HeroCopyBlock
} from "../../../components/Heros"
import Button from "../../../components/Button"
import Spacer from "../../../components/Spacer"
import Carousel from "../../../components/Carousel"
import MobileCarousel from "../../../components/MobileCarousel"

import lizImg from "./images/liz.png";
import vickyImg from "./images/vicky.png";
import cerealImg from "./images/cereal.png";
import gymImg from "./images/gym.png";
import tvImg from "./images/tv.png";
import townHallImg from "./images/townhall.png";

import styles from "./styles.module.scss"

const KeepingUpWithContactImages = () => (
  <div className={styles.keepUpContactImages}>
    <div className={styles.menCoding}>
      <img src={cerealImg} alt="men coding" />
    </div>
    <div className={styles.birdseyecoding}>
      <img src={tvImg} alt="coding from above" />
    </div>
    <div className={styles.salesImg}>
      <img src={gymImg} alt="sales" />
    </div>
  </div>
)

const carouselData = [
  {
    img: vickyImg,
    title: "Vicky Murphy",
    colour: "green",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  },
  {
    img: vickyImg,
    title: "Ade Brooks",
    colour: "dark",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  },
  {
    img: vickyImg,
    title: "Cliff Okoro",
    colour: "blue",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  }
]

const JoinUs = () => (
  <Layout JobSection>
    <Seo title="Sorted | Join us" keywords={[`Apply`, `Jobs`, `Apply`, `Career`, `Journey`]} />
    <PrimaryHero theme="pink">
      <HeroCopyBlock title={["Create", "Magic."]}>
        <p>As you’d expect from a company building the intelligent software that’s transforming the retail industry; Sorted is no ordinary organisation. And our people are anything but ordinary.</p>
      </HeroCopyBlock>
    </PrimaryHero>

    <Spacer size="medium" sides="topbottom">
      <div className="container">
        <h2 className="text-center text-pink">Behind the scenes at one of Manchester’s<br/>most exciting tech businesses.</h2>
      </div>
      <div className="container-ultrathin">
        <p className="text-center">It’s challenging, it’s demanding, it’s exciting and it’s a lot of fun; we work hard and we play hard. Our people embody the Sorted personality traits—we’re smart, brave, agile and precise. We’re all charging, full speed ahead, on our mission of turning delivery into a delight.</p>
        <p className="text-center">To get a real understanding of what it’s like to work here, you need to be in our office and feel it for yourself. But here are a few sneaky peeks to explain a bit more about the way we do things</p>
      </div>
    </Spacer>

    <DualImageBlock img={lizImg} theme="purple" maxHeight={700}>
      <h3>Our culture</h3>
      <p>It’s not just something we talk about. It lives and breathes and evolves as we grow. Find out a bit more about what we get up to—from socials to training.</p>
      <Button theme="white"><Link to="/about-us/culture">More details</Link></Button>
    </DualImageBlock>

    <Spacer size="large" sides="topbottom">
      <div className="container-large-fluid">
        <RightDualComponent
          rightComponent={<KeepingUpWithContactImages />}
          theme="pink"
        >
          <div className={styles.benefits}>
            <h3>Our benefits.</h3>
            <p>The bit everyone is interested in, right? We aim to delight you by giving you one of the most attractive compensation packages this side of the Silicon Roundabout.</p>
            <div>
              <Button theme="white"><Link to="/about-us/join-us/benefits">More details</Link></Button>
            </div>
          </div>
        </RightDualComponent>
      </div>
    </Spacer>

    <DualImageBlock img={townHallImg} theme="grey" maxHeight={700} reverse>
      <h3>Sorted House.</h3>
      <p>Our Tech House has been named in the top three ‘Most Inspired Spaces’ in the North. The whole environment is inspired and designed in total collaboration with our people, so they obviously know what they’re talking about.</p>
      <div>
        <Button theme="white"><Link to="/about-us/sorted-house">More details</Link></Button>
      </div>
    </DualImageBlock>

    <Spacer size="medium" sides="topbottom">
      <div className="container">
        <h2 className="text-center text-pink">Don't just take our word for it.</h2>
      </div>
      <div className="container-ultrathin">
        <p className="text-center">All of the above is for our people. To see what they think about working here, we asked them to share their honest thoughts about Sorted.</p>
      </div>
    </Spacer>

    <div className={styles.desktopCarousel}>
      <Carousel
        copyHeight="195px"
        items={carouselData}
        transparent
      />
    </div>

    <div className={styles.mobileCarousel}>
      <MobileCarousel items={carouselData} transparent />
    </div>

  </Layout>
)

export default JoinUs
