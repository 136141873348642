import React, { Component } from "react"

import cx from "classnames"
import styles from "./styles.module.scss"

import { RightArrow } from "../Icons";

class Carousel extends Component {
  state = { active: 0 }

  setActive = (id) => {
    console.log(id);
    this.setState({ active: id })
  };

  render() {
    const {
      detail,
      items,
      copyHeight,
      transparent,
    } = this.props;
    const activeId = this.state.active;

    return (
      <div className={styles.carousel}>
        {items.map((item, i) => (
          <div
            className={
              cx(styles.carouselItem, activeId === i && styles.carouselItemActive, styles[item.colour], styles[`width${items.length}`])
            }
            onClick={() => this.setActive(i)}
          >
            <div className={styles.carouselItemMain}>
              <img src={item.img} className={styles.carouselItemMain} alt="carousel item"/>
            </div>
            <div
              className={
                cx(styles.carouselItemCopy, transparent && styles.transparent, detail && styles.carouselItemCopyDetail)
              }
              style={
                {
                  height: copyHeight,
                  marginTop: transparent ? `-${copyHeight}` : 0
                }
              }
            >
              <h4>{item.title}</h4>
              {item.header && (<p  className={styles.headerText}>{item.header}</p>)}
              <p>{item.copy}</p>
              <div className={cx(styles.arrowHolder, activeId === i && styles.arrowHolderActive)}>
                <RightArrow className={styles.arrow} />
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default Carousel
